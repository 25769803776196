* {
}
html, body {
  background-color: #404040;
  font-family: 'Open Sans', sans-serif;
  /* max-width: 100%;
  overflow-x: hidden; */
}
.App {
  text-align: center;
}
.page1 {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: red; */
  /* z-index: -1; */
  transform: translateY(0);
}
.header {
  position: absolute;
  width: 100%;
  padding: 20px 40px 0;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
  display: flex;
  flex-direction: row;
}
.brandpage {
  height: 100vh;
  width: 100vw;
  /* background-color: white; */
  background: rgb(253,254,255);
  background: radial-gradient(circle, rgba(253,254,255,1) 53%, rgba(245,245,245,1) 100%);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.contentContainer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  background-color: #5BA8E2;
  right: 0;
  bottom: -100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 2vw;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
}
.teamWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.teamContainer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  background-color: #1E2664;
  right: -100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 2vw;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
}
.page2 {
  /* position: absolute; */
  /* top: 25vh; */
  padding-top: 50vh;
  width: 100vw;
  height: 200vh;
  background-color: #5BA8E2;
  color: #fff;
  z-index: 0;
  /* transition: opacity 200ms;: top 2s; */
}
.logo-container {
  display: flex;
  width: 308px;
  height: 528px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  /* transform: scale(0.5); */
}
.logo-container-small {
  display: flex;
  width: 54px;
  height: 45px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.logo-block-dark {
  width: 40px;
  height: 40px;
  margin: 2px;
  background-color: #1E2664;
}
.logo-block-light {
  width: 40px;
  height: 40px;
  margin: 2px;
  background-color: #5BA8E2;
}
.logo-block-open {
  width: 40px;
  height: 40px;
  margin: 2px;
  background-color: rgba(0,0,0,0.02);
}
.small-block {
  width: 7px;
  height: 7px;
  margin: 1px;
}
.logo-block-open.small-block {
  background-color: transparent;
}
.companyName {
  /* position: absolute;
  bottom: 5px; */
  text-align: left;
}
.title {
  font-size: 4rem;
  font-weight: 600;
  margin-top: 12px;
}
.title span {
  color: #1E2664;
}
.title span.al {
  color: #5BA8E2;
}
.subtitle {
  color: #1E2664;
  letter-spacing: 0.25rem;
  font-size: 1rem;
  line-height: 0.2rem;
}
.small .title {
  font-size: 1.4rem;
  font-weight: 600;
}
.small .subtitle {
  color: #1E2664;
  letter-spacing: 0rem;
  font-size: 0.5rem;
}

.icon-scroll {
  position: absolute;
  bottom: 15px;
  left: calc(50% - 15px);
  width: 30px;
  height: 50px;
  box-shadow: inset 0 0 0 1px #1E2664;
  border-radius: 25px;
  background-color: #fefdff;
  border: 2px solid #fefdff;
  transition: transform 100ms;
}
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll.hide {
  transform: translateY(80px);
}

.icon-scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #1E2664;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}
.textBlock {
  max-width: 400px;
  color: white;
  font-size: 1.2rem;
}
.h1 {
  font-size: 4rem;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}
.masked {
  position: relative;
  display: inline-block;
  margin: 6px 0;
}
.masked:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  background-color: #5BA8E2;
  top: 0;
  left: 0%;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.masked.dark:before {
  background-color: #1E2664;
  display: inline-block;
}
.masked.activated:before {
  /* animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-name: revealmask; */
  animation: revealmask 800ms forwards;
}
.masked:after {
  content: ' ';
  display: block;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  /* right: 0; */
  bottom: 0;
  width: 0%;
  z-index: 1;
}
.masked.activated:after {
  /* animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-name: revealmaskWhite; */
  animation: revealmaskWhite 800ms forwards;
}
.delay1 {
  animation-delay: 100ms;
}
.delay2 {
  animation-delay: 150ms;
}
@keyframes revealmask {
  0% {
    width: 100%;
    left: 0%;
  }
  50% {
    width: 0%;
    left: 100%;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}
@keyframes revealmaskWhite {
  0% {
    width: 0%;
    left: 0%;
  }
  50% {
    width: 100%;
    left: 0%;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

.photoHeader {
  max-width: 400px;
  color: white;
  font-size: 1.4rem;
  display: flex;
  padding: 0 10px;
}
.photoBlock {
  max-width: 460px;
  color: white;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.person {
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
  padding: 10px;
  width: 200px;
  margin-right: 30px;
}
.photo {
  width: 150px;
  /* height: 266px; */
  padding: 1px;
  overflow: hidden;
}
img.personPhoto {
  width: 100%;
  height: 100%;
  -webkit-filter: contrast(190%) brightness(110%) saturate(160%) sepia(80%) grayscale(29%) hue-rotate(175deg);
  filter: contrast(190%) brightness(110%) saturate(160%) sepia(80%) grayscale(29%) hue-rotate(175deg);
  transition: transform 300ms;
}
.name {
  font-size: 1rem;
}
.position {
  font-size: 0.8rem;
  color: lightgray;
  margin-top: -5px;
}
.contact-icons {
  opacity: 0;
  transition: opacity 300ms;
}
.contact-icons a {
  margin-right: 10px;
  color: white;
}
.person:hover img.personPhoto {
  transform: scale(1.1);
  /* filter: none; */
}
.person:hover .contact-icons {
  opacity: 1;
}


@media only screen and (min-width: 1024px) {
  .header {
    padding: 70px 120px 0;
  }
  .contentContainer {
    width: 50vw;
    padding: 0 5vw;
  }
  .teamWrapper {
    left: 50vw;
    overflow-y: scroll;
  }
  .teamContainer {
    width: 50vw;
    right: -50vw;
    padding: 0 5vw;
  }
  .photo {
    width: 200px;
  }
}